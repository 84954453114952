#skills {
	width: 100%;
	height: 80vh;
	position: relative;
	overflow: hidden;
	background: url('../../images/backgrounds/code.jpg') no-repeat center center fixed;
	background-size: cover;
	background-color: grey;
	
}

.skills_content {
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

}

#skills_title {
	text-align: center;
	font-size: 50px;
	margin: auto;
	letter-spacing: 5px;
	background-color: #72A5AA;
	border-radius: 25px;
	width: 700px;
	font-family: 'Bodini';
	
}
#skills_groups {
	display: flex;
	
}

.hard_skills, .soft_skills, .technical_skills {
	flex: 1;
	width: 500px;
	padding: 1rem;
	background-color: #72A5AA;
	margin: 50px;
	border-radius: 25px;
	box-shadow: 0 10px 50px rgba(0,0,0,0.3);
	position: relative;
	color: #333333;
}

.hard_skills h5, .soft_skills h5, .technical_skills h5 {
	text-align: center;
	margin: 0px;
	text-decoration: underline;
	font-size: 25px;

}


#skills_groups ul {
	line-height: 150%;
	letter-spacing: 2px;
	padding-left: 20px;
}

@media only screen and (max-width: 1100px) {
	#skills {
		height: auto;


	}

	#skills_title {
		width: 50%;
		font-size: 30px;
	}

	.skills_content {
		position: initial;
		top: initial;
		left: initial;
		transform: initial;
		width: auto;
		
	}

	#skills_groups {
		flex-direction: column;
	}

	.hard_skills, .soft_skills, .technical_skills {
		margin: 10px auto;
	}
	
}

@media only screen and (max-width: 600px) {
	.hard_skills, .soft_skills, .technical_skills {
		width: 200px;
	}
}