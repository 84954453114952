@import url(https://fonts.googleapis.com/css2?family=Bangers&family=Mulish:wght@300;400&family=Oswald:wght@300;400&family=Sriracha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Figtree&family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Bodoni";
    src: url(/static/media/Bodoni.ed9d9099.ttf) format("truetype");
}

body a(not:portfolio_container) {
  cursor: url('/images/flashlight_cursor.png'), auto;
}

/* Home Button Styling */
.home_button {
  color: #F4F4F4;
  background-color: #333333;
  bottom: 0;
  display: block;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  margin-bottom: 50px;
  margin-top: 25px;
  border: solid #F4F4F4 1px;
  font-family: 'Bangers';
  letter-spacing: 2px;
  font-size: 25px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.home_button_portfolio:hover {
  transform: scale(1.1);
  text-decoration: none;
}


/* Footer Styling */

hr.footer_divider {
  border: none;
  border-top: 0.1px solid #D1D1D1;
  margin: 10px 30px 10px 30px;
}

.footer_container {
  font-size: 12px;
  font-family: Arial;
  color: #D1D1D1;
  display: flex;
  text-align: left;
  margin-left: 30px;
}

.footer a {
  text-decoration: none;
}

.footer a:link, .footer a:visited, .footer a:active {
  color: #D1D1D1;
}

.footer a:hover {
  color: #333333;
}

.footer_title {
  text-decoration: underline;
}

.copyright {
  flex: 1 1;

}

.more_info {
  flex: 1 1;
  padding-left: 50px;
}

.more_info p {
  margin: 5px;
}

.social_links {
  flex: 1 1;
}

.social_links ul {
  list-style-type: none;
}


.footer {
  background-color: #333;
  margin: 0px;
}

.footer_container {
  font-size: 12px;
  font-family: Arial;
  color: #D1D1D1;
  display: flex;
  text-align: left;
  margin-left: 30px;
}

.footer a {
  text-decoration: none;
}

.footer a:link, .footer a:visited, .footer a:active {
  color: #D1D1D1;
}

.footer a:hover {
  color: #8b0000;
}

.footer_title {
  text-decoration: underline;
}

.copyright {
  flex: 1 1;

}

.more_info {
  flex: 1 1;
  padding-left: 50px;
}

.more_info p {
  margin: 5px;
}

.social_links {
  flex: 1 1;
}

.social_links ul {
  list-style-type: none;
}

.footer_icon {
  padding: 10px;
}

/* Footer Mobile Styling */

@media only screen and (max-width: 600px) {
  .footer_container {
    flex-direction: column;
    width: 350px;
    margin: 10px auto 10px auto;
    font-size: 150%;
    padding-top: 10px;
    text-align: center;
  }

  .more_info {
    padding: initial;
  }

  .social_links ul {
    padding-inline-start: unset;
  }

  .footer_container p, a, li {
    margin: 20px;
  }

  .more_info {
    display: none;
  }


  hr.footer_divider {
    width: 100%;
    margin: 25px 0 10px 0;
    padding: 0;
  }

  
}
.options_container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; 
  align-items: center; 
  margin: 0;
  padding: 0;
  background-color: #F0F0F0;

}

span {
  display: block;
}

.nav-link {
  flex: 1 1;
}

.about_box, .portfolio_box, .work_box, .skills_box, .locations_box, .home_box {
  flex: 1 1;
  height: 300px;
  background-color: #420000;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
  text-align: center;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;

}


.options_container h4 {
  font-size: 25px;
  letter-spacing: 3px;
  color: #D1D1D1;
  line-height: 200px;
}

.options_container a {
  text-decoration: none;
  cursor: crosshair;
}

.about_box {
  background-image: url(/static/media/about_cloud.a9c6dad6.png);

}

.portfolio_box {
  background-image: url(/static/media/portfolio_cloud.85407e68.png);

}

.work_box {
  background-image: url(/static/media/work_cloud.d249efc5.png);

}

.skills_box {
  background-image: url(/static/media/skills_cloud.6d36d4dc.png);

}

.locations_box {
  background-image: url(/static/media/locations_cloud.5dbe3bb6.png);

}

.home_box {
  background-image: url(/static/media/about_cloud.a9c6dad6.png);
  cursor: crosshair;
}

.about_box:hover, .portfolio_box:hover, .work_box:hover, .skills_box:hover, .locations_box:hover, .home_box:hover {
  background-image: url(/static/media/redcloud.d4538b67.gif);
}

.about_box:hover h4, .portfolio_box:hover h4, .work_box:hover h4, .skills_box:hover h4, .locations_box:hover h4, .home_box:hover h4 {
  animation: textgrow 4s infinite ease-in-out;
}

@keyframes textgrow {
  0% {
    letter-spacing: 5px;
  }
  25% {
    letter-spacing: 3px;
  }
  50% {
    letter-spacing: 5px;
  }
  75% {
    letter-spacing: 3px;
  }
  100% {
    letter-spacing: 5px;
  }
}

.home_box {
    display: none;
  }

/* iPad Styling*/

@media only screen and (max-width: 1250px) {
    .options_container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      flex-direction: column;
    }

    .nav-link a {
      margin: 0px;
    }

    .box {
      width: 100%;
    }

    .home_box {
      display: none;
    }
}

/* Mobile Styling*/

@media only screen and (max-width: 600px) {
  .options_container {
    width: unset;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .box {
    flex: 0 1 45%;
    margin-right: auto;
    margin-left: auto;

  }

  .home_box {
    display: block;
  }

}
.total-body {
  background-color: #000000;
  font-family: 'Oswald';
  font-weight: 300;
}

.main_container {
  position: relative;
  text-align: center;
  color: white;
}

.welcome_container {
  width: 100%;
  height: 95vh;
  overflow: hidden;
  background-color: #F4F4F4;
  color: #333333;
  background: url(/static/media/whitekeys.5e08a64e.jpg) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered_text {
  text-align: center;
  color: #333333;
  

}

.centered_text h1 {
  animation: slideInFromLeft 2s ease-out;
  letter-spacing: 5px;
  font-size: 50px;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateY(-80%);
    letter-spacing: 40px;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    letter-spacing: 5px;
  }
}


/* Make Self Title expand out  */
.centered_text h4 {
  animation: slowAppear forwards;
  animation-delay: 2s;
  animation-duration: 3s;
  letter-spacing: -1px;
  
}

@keyframes slowAppear {
  0% {letter-spacing: -1px;}
  100% {letter-spacing: 4px;}
}

.centered_text a:link, .centered_text a:visited, .centered_text a:active {
  color: #333333;
}

.centered_text a:hover {
  color: #8b0000;
}



.status_notifications {
  display: flex;
  flex-direction: row;
  width: 700px;
}

.statuses {
  flex: 1 1;
  margin: 10px;
}

/* .statuses p{
  letter-spacing: 1px;
}

.statuses a {
  letter-spacing: 2px;
  font-weight: 500;
} */

.status_title_logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  padding-left: 20px;
  
}




.social_icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin: 20px 30px;
}

.icon:hover {
  animation: grow 1s ease-out;
  animation-fill-mode: forwards;
}

.social_icons a:link, .social_icons a:visited, .social_icons a:active {
  color: #D1D1D1;
}

.social_icons a:hover {
  color: #8b0000;
  
}

@keyframes grow {
  0% {transform: scale(1);}

  100% {transform: scale(1.2);}
}



.down_arrow {
  color: #8b0000;
  animation: bouncing 2s infinite ease-in-out;
  bottom: 0;
  display: block;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

@keyframes bouncing {
    0% {bottom: 0;}
    50% {bottom: 10px;}
    100% {bottom: 0;}
}



/* Hero Section Responsive Styling */

@media only screen and (max-width: 1250px)  {

  .status_notifications {
    display: flex;
    flex-flow: column;
  }

  .status_title_logo {
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .social_icons a  {
    margin: 5px;
  }
}
/* About Page */

#about {
  width: 100%;
  height: 800px;
  overflow: hidden;
  background-color: #333333;
  color: #333333;
  background: url(/static/media/storm.25773184.jpg) no-repeat center center fixed;
  background-size: cover;

  
}

.about_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 1000px;
  background-color: #F4F4F4;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px 25px;
  box-shadow: 0 10px 50px rgba(0,0,0,0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.headshot {
  flex: 0 1;
  width: auto;
  height: 300px;
  width: 300px;
  margin: 30px 0px 30px 30px;
  border-radius: 100%;
  box-shadow: 0 10px 50px rgba(0,0,0,0.3);
}

.about_me_title {
  font-size: 30px;
  margin: 10px 0px 10px 0px;
  font-family: 'Bangers';
}

.about_content {
  padding-left: 50px;
  flex: 1 1 100px;
  letter-spacing: 1.25px;
}

.about_content p {
  margin: 10px 20px 10px 0px;
  max-width: 500px;
  font-family: 'Arial';
}

/* About Page iPad Styling*/

@media only screen and (max-device-width: 1200px) {
  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .about_container {
    width: 700px;
    margin-top: 20px;
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    line-height: 30px;
  }

}

/* About Page Mobile Styling */

@media only screen and (max-device-width: 720px) {

  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
  }

  .about_container {
    width: 300px;
    margin-top: 20px;
    position: initial;
    top: initial;
    left: initial;
    transform: initial;

      
  }

  .headshot {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

  }

  .about_content {
    display: block;
    max-width: 300px;
    justify-content: center;
    padding: initial;
    margin: 20px; 
  }

  .about_me_title {
    font-size: 2em;
    letter-spacing: 5px;
    text-align: center;
  }
}
.form-container {
  margin: 50px auto;
  width: 1000px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 600px;
  display: flex;
}

.form-content-left {
  flex: 1 1;
  background-color: #552930;
  background-image: linear-gradient(315deg, #552930 0%, #825658 74%);
  border-radius: 10px 0 0 10px;
  position: relative;
}

.form-img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.form-img-2 {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fff;
}

.form-content-right {
  flex: 1 1;
  border-radius: 0 10px 10px 0;
  position: relative;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
  font-family: Arial;
}

.help-description {
  height: 150px;
}

.selector {
  width: 50%;
  color: #595959;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.help-description::placeholder {
  color: #595959;
  font-size: 12px;
  font-family: Arial;


}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 2px;
  background-image: linear-gradient(315deg, #552930 0%, #825658 74%);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  background-color: #754C52;
  background-image: linear-gradient(315deg, #754C52 0%, #7B4B4F 74%);
  transition: all 0.4s ease-out;
}


/* iPad Styling */
@media only screen and (max-width: 1024px) {
  .form-container {
    flex-flow: column;
    width: 500px;
    height: auto;
    
  }

  .form-content-left {
    justify-content: center;
    align-content: center;
    display: flex;
    border-radius: 10px 10px 0 0;

  }

  .form-content-right {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0 0 10px 10px;

  }

  .form {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    width: initial;
  }

  .form-img {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    margin: 20px;
  } 

  .form-input-btn {
    margin-bottom: 10px;
  }
}

/* Mobile Styling */
@media only screen and (max-width: 1024px) {
  .form-container {
    width: 300px;
  }
}
/* Portfolio */

.portfolio {
	width: 100%;
	position: relative;
	overflow: hidden;
	background: url(/static/media/swamp.6998779c.jpg) no-repeat center center fixed;
	background-size: cover;
	background-color: grey;

}

.portfolio_title {
	text-align: center;
	font-size: 50px;
	font-family: 'Bodoni';
	letter-spacing: 5px;
	margin: 10px;
	background-color: #333;
	color: #F4F4F4;
	border-radius: 25px;
	width: 600px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.17););
	text-transform: uppercase;
	padding: 10px 20px; 
}

.portfolio_subheader {
	margin: 10px;
	background-color: #333;
	color: #F4F4F4;
	border-radius: 25px;
	width: 600px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 20px;
}

.portfolio_description {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 15px;
	width: 600px;
	
}

.cards {
	margin: 0px;
}

h1 {
  text-align: center;
}

.cards_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.cards_wrapper {
  position: relative;
  margin: 20px 0 45px;
}

.cards_items {
  margin-bottom: 24px;
  padding: 0;
}

.cards_item {
  display: flex;
  flex: 1 1;
  margin: .5rem 1rem .5rem 1rem;
  border-radius: 10px;
  background-color: #F4F4F4;
}

.cards_item_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards_items a:hover, .cards_items a:active, .cards_items a:visited, .cards_items a:link {
	text-decoration: none;
	color: #252e48;

}

.cards_item_pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  margin: 10px;
  border-radius: 10px;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards_item_pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards_item_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards_item_img:hover {
  transform: scale(1.1);
}

.cards_item_info {
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;

}

.cards_item_text {
	flex: 1 1;
	color: #252e48;
	font-size: 18px;
	line-height: 24px;
	margin: 10px 0 0;
	padding: 0px;
}

.cards_item_description {
	flex-flow: 1;
	max-width: 250px;

}

/* Portfolio iPad Styling */



@media only screen and (min-width: 1024px) {
  .cards_items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards_item {
    margin-bottom: 2rem;
  }

  .portfolio_title {
  	font-size: 30px;
  	width: 50%;
  }

  .portfolio_subheader {
  	font-size: 15px;
  	width: 50%;
  }

  .portfolio_description {
  	width: 70%;
  }
}


@media only screen and (max-device-width: 600px) {

	.portfolio_title {
		font-size: 15px;
	}

	.portfolio_subheader {
		font-size: 10px;
	}

	.portfolio_description {
		font-size: 12px;
	}

}

.work {
	width: 100%;
	height: 95vh;
	position: relative;
	overflow: hidden;
	background: url(/static/media/sanfrancisco.3ac933ff.jpg) no-repeat center center fixed;
	background-size: cover;
	background-color: grey;
	
}

.work_content {
	width: 1000px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	margin-bottom: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.work_title {
	text-align: center;
	color: #333333;
	font-size: 50px;
	letter-spacing: 5px;
	margin: auto;
	background-color: #D2B48C;
	width: 200px;
	border-radius: 25px;
	font-family: 'Bodini';
}

.work_history {
	line-height: 150%;
	display: flex;
}

.work_content img {
	margin: 0 auto;
	display: block;
}

.roles, .organizations, .non_profit_role, .non_profit_org {
	flex: 1 1;
	width: 100%;
	padding: 1rem;
	background-color: #D2B48C;
	margin: 10px;
	border-radius: 25px;
	box-shadow: 0 10px 50px rgba(0,0,0,0.3);
	position: relative;
	color: #333333;
}

.roles h5, .organizations h5, .non_profit_role h5, .non_profit_org h5 {
	text-align: center;
	font-size: 17px;

}

.roles ul, .organizations ul, .non_profit_role ul, .non_profit_org ul {
	padding-left: 10px;
	
}

/* iPad Styling */
@media only screen and (max-width: 1024px) {
	.work {
		height: 100%;
	}

	.work_content {
		position: initial;
		top: initial;
		left: initial;
		transform: initial;
		width: 250px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.work_history {
		flex-direction: column;
	}

	.work_history {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;		

	}

	.work_history h5 {
		font-size: 150%;
	}

	.work_history ul {
		list-style-type: initial;
		font-size: 120%;
		margin-left: 33px;
	}
}
#skills {
	width: 100%;
	height: 80vh;
	position: relative;
	overflow: hidden;
	background: url(/static/media/code.c8563370.jpg) no-repeat center center fixed;
	background-size: cover;
	background-color: grey;
	
}

.skills_content {
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

}

#skills_title {
	text-align: center;
	font-size: 50px;
	margin: auto;
	letter-spacing: 5px;
	background-color: #72A5AA;
	border-radius: 25px;
	width: 700px;
	font-family: 'Bodini';
	
}
#skills_groups {
	display: flex;
	
}

.hard_skills, .soft_skills, .technical_skills {
	flex: 1 1;
	width: 500px;
	padding: 1rem;
	background-color: #72A5AA;
	margin: 50px;
	border-radius: 25px;
	box-shadow: 0 10px 50px rgba(0,0,0,0.3);
	position: relative;
	color: #333333;
}

.hard_skills h5, .soft_skills h5, .technical_skills h5 {
	text-align: center;
	margin: 0px;
	text-decoration: underline;
	font-size: 25px;

}


#skills_groups ul {
	line-height: 150%;
	letter-spacing: 2px;
	padding-left: 20px;
}

@media only screen and (max-width: 1100px) {
	#skills {
		height: auto;


	}

	#skills_title {
		width: 50%;
		font-size: 30px;
	}

	.skills_content {
		position: initial;
		top: initial;
		left: initial;
		transform: initial;
		width: auto;
		
	}

	#skills_groups {
		flex-direction: column;
	}

	.hard_skills, .soft_skills, .technical_skills {
		margin: 10px auto;
	}
	
}

@media only screen and (max-width: 600px) {
	.hard_skills, .soft_skills, .technical_skills {
		width: 200px;
	}
}
/* Import Figtree, Satisfy from Google Fonts */

/* Make Super Red */
:root {
  --super-red: #A71930;
}


.timeline {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow-x: hidden;
  background: url(/static/media/plane.7a5b39e3.jpg) no-repeat center center fixed;
  background-size: cover;
  background-color: grey;
  height: 95vh;

}

.timeline h3 {
  color: #333333;
  font-size: 50px;
  letter-spacing: 5px;
  border-radius: 25px;
  background-color: #F4F4F4;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  font-family: 'Bodini';
}

.timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 200px 0;
  margin-left: -100px;
  transition: all 1s;

}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 75px;
  height: 3px;
  background: #F4F4F4;

}



.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: red;
}

/* Bubbles */
.timeline ol li div {
  position: absolute;
  left: calc(100% + 6px);
  width: 120px;
  height: 75px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: #333333;
  background: #F4F4F4;
}

.timeline ol li div::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
  border-radius: 25px 25px 25px 0px;
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: white transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  top: calc(100% + 16px);
  border-radius: 0px 25px 25px 25px;
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.timeline time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Figtree';
  margin-bottom: 8px;
}

.timeline button {
  color: #333333;
  font-size: 20px;
  letter-spacing: 5px;
  border-radius: 25px;
  background-color: #F4F4F4;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  font-family: 'Bangers', Arial;

}

/* Responsive Styling */

@media only screen and (max-width: 1375px) {
  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url(/static/media/plane.7a5b39e3.jpg) no-repeat center;
    background-size: cover;
    background-position-x: -1500px;

    
  }

  #locations {
    display: flex;
  }

  .timeline h3 {
    color: #333333;
    font-size: 200%;
    letter-spacing: 4px;
    border-radius: 25px;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 10px;


  }


  /* Whole Timeline List */
  .timeline ol {
    flex-flow: column nowrap;
    width: 100%;
    margin-left: initial;
    padding-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;

  }

  /* Vertical Line First Part */
  .timeline ol li {
    display: block;
    height: 150px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;


  }

  /* Vertical Line Rest of Line */

  .timeline ol li:not(:first-child) {
    margin-left: auto;
    margin-right: auto;

  }

  /* Purple Dots */

  .timeline ol li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1px);   
    width: 25px;
    height: 25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: var(--super-red);

    
  }

  /* Text Bubbles*/
  .timeline ol li div {
    position: absolute;
    transform: translateX(-50%);
    width: 110px;
    height: 125px;
    padding: 15px;
    
    white-space: normal;
    color: #333333;
    background: #F4F4F4;
    font-size: 16px;
  }

  /* Little Triangles */

  .timeline ol li div::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ol li:nth-child(odd) div {
    top: initial;
    bottom: 60px;
    left: calc(100% - 30px);
    transform: translateX(-100%);
    border-radius: 25px 25px 0px 25px;
  }

  .timeline ol li:nth-child(odd) div::before {
    top: 140px;
    left: 100%;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent #F4F4F4;
  }

  .timeline ol li:nth-child(even) div {
    left: calc(100% + 89px);
    top: initial;
    bottom: calc(100% + -90px);
    border-radius: 25px 25px 25px 0px;
  }

  .timeline ol li:nth-child(even) div::before {
    top: 140px;
    left: -13px;
    border-width: 15px 15px 0 0;
    border-color: transparent #F4F4F4 transparent transparent;
  }

  .timeline time {
    display: block;
    font-size: 115%;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .timeline button {
    font-size: 200%;
    letter-spacing: 5px;
    border-radius: 25px;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

  }
}


/* iPad Styling */

@media only screen and (max-width: 700px) {
  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url(/static/media/plane.7a5b39e3.jpg) no-repeat center;
    background-size: cover;
    background-position-x: -1750px;

    
  }
}



/* iPhone X Styling*/
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {

  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url(/static/media/plane.7a5b39e3.jpg) no-repeat center;
    background-size: cover;
    background-position-x: -2250px;

    
  }

  #locations {
    display: flex;
  }

  .timeline h3 {
    color: #333333;
    font-size: 200%;
    letter-spacing: 4px;
    border-radius: 25px;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 10px;


  }


  /* Whole Timeline List */
  .timeline ol {
    flex-flow: column nowrap;
    width: 100%;
    margin-left: initial;
    padding-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;

  }

  /* Vertical Line First Part */
  .timeline ol li {
    display: block;
    height: 200px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;


  }

  /* Vertical Line Rest of Line */

  .timeline ol li:not(:first-child) {
    margin-left: auto;
    margin-right: auto;

  }

  /* Purple Dots */

  .timeline ol li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1px);   
    width: 25px;
    height: 25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: purple;

    
  }

  /* Text Bubbles*/
  .timeline ol li div {
    position: absolute;
    transform: translateX(-50%);
    width: 110px;
    height: 125px;
    padding: 15px;
    
    white-space: normal;
    color: #333333;
    background: #F4F4F4;
    font-size: 16px;
  }

  /* Little Triangles */

  .timeline ol li div::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ol li:nth-child(odd) div {
    top: initial;
    bottom: 60px;
    left: calc(100% - 30px);
    transform: translateX(-100%);
    border-radius: 25px 25px 0px 25px;
  }

  .timeline ol li:nth-child(odd) div::before {
    top: 140px;
    left: 100%;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent #F4F4F4;
  }

  .timeline ol li:nth-child(even) div {
    left: calc(100% + 89px);
    top: initial;
    bottom: calc(100% + -90px);
    border-radius: 25px 25px 25px 0px;
  }

  .timeline ol li:nth-child(even) div::before {
    top: 140px;
    left: -13px;
    border-width: 15px 15px 0 0;
    border-color: transparent #F4F4F4 transparent transparent;
  }

  .timeline time {
    display: block;
    font-size: 115%;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .timeline button {
    font-size: 200%;
    letter-spacing: 5px;
    border-radius: 25px;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

  }

}

@media only screen and (max-width: 600px) {
  .timeline p, a, li {
    margin: initial;
  }
}
