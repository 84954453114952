/* About Page */

#about {
  width: 100%;
  height: 800px;
  overflow: hidden;
  background-color: #333333;
  color: #333333;
  background: url('../../images/backgrounds/storm.jpg') no-repeat center center fixed;
  background-size: cover;

  
}

.about_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 1000px;
  background-color: #F4F4F4;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px 25px;
  box-shadow: 0 10px 50px rgba(0,0,0,0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.headshot {
  flex: 0;
  width: auto;
  height: 300px;
  width: 300px;
  margin: 30px 0px 30px 30px;
  border-radius: 100%;
  box-shadow: 0 10px 50px rgba(0,0,0,0.3);
}

.about_me_title {
  font-size: 30px;
  margin: 10px 0px 10px 0px;
  font-family: 'Bangers';
}

.about_content {
  padding-left: 50px;
  flex: 1 1 100px;
  letter-spacing: 1.25px;
}

.about_content p {
  margin: 10px 20px 10px 0px;
  max-width: 500px;
  font-family: 'Arial';
}

/* About Page iPad Styling*/

@media only screen and (max-device-width: 1200px) {
  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .about_container {
    width: 700px;
    margin-top: 20px;
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    line-height: 30px;
  }

}

/* About Page Mobile Styling */

@media only screen and (max-device-width: 720px) {

  #about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
  }

  .about_container {
    width: 300px;
    margin-top: 20px;
    position: initial;
    top: initial;
    left: initial;
    transform: initial;

      
  }

  .headshot {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

  }

  .about_content {
    display: block;
    max-width: 300px;
    justify-content: center;
    padding: initial;
    margin: 20px; 
  }

  .about_me_title {
    font-size: 2em;
    letter-spacing: 5px;
    text-align: center;
  }
}