.form-container {
  margin: 50px auto;
  width: 1000px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 600px;
  display: flex;
}

.form-content-left {
  flex: 1;
  background-color: #552930;
  background-image: linear-gradient(315deg, #552930 0%, #825658 74%);
  border-radius: 10px 0 0 10px;
  position: relative;
}

.form-img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.form-img-2 {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fff;
}

.form-content-right {
  flex: 1;
  border-radius: 0 10px 10px 0;
  position: relative;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
  font-family: Arial;
}

.help-description {
  height: 150px;
}

.selector {
  width: 50%;
  color: #595959;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.help-description::placeholder {
  color: #595959;
  font-size: 12px;
  font-family: Arial;


}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 2px;
  background-image: linear-gradient(315deg, #552930 0%, #825658 74%);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  background-color: #754C52;
  background-image: linear-gradient(315deg, #754C52 0%, #7B4B4F 74%);
  transition: all 0.4s ease-out;
}


/* iPad Styling */
@media only screen and (max-width: 1024px) {
  .form-container {
    flex-flow: column;
    width: 500px;
    height: auto;
    
  }

  .form-content-left {
    justify-content: center;
    align-content: center;
    display: flex;
    border-radius: 10px 10px 0 0;

  }

  .form-content-right {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0 0 10px 10px;

  }

  .form {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    width: initial;
  }

  .form-img {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    margin: 20px;
  } 

  .form-input-btn {
    margin-bottom: 10px;
  }
}

/* Mobile Styling */
@media only screen and (max-width: 1024px) {
  .form-container {
    width: 300px;
  }
}