
.work {
	width: 100%;
	height: 95vh;
	position: relative;
	overflow: hidden;
	background: url('../../images/backgrounds/sanfrancisco.jpg') no-repeat center center fixed;
	background-size: cover;
	background-color: grey;
	
}

.work_content {
	width: 1000px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	margin-bottom: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.work_title {
	text-align: center;
	color: #333333;
	font-size: 50px;
	letter-spacing: 5px;
	margin: auto;
	background-color: #D2B48C;
	width: 200px;
	border-radius: 25px;
	font-family: 'Bodini';
}

.work_history {
	line-height: 150%;
	display: flex;
}

.work_content img {
	margin: 0 auto;
	display: block;
}

.roles, .organizations, .non_profit_role, .non_profit_org {
	flex: 1;
	width: 100%;
	padding: 1rem;
	background-color: #D2B48C;
	margin: 10px;
	border-radius: 25px;
	box-shadow: 0 10px 50px rgba(0,0,0,0.3);
	position: relative;
	color: #333333;
}

.roles h5, .organizations h5, .non_profit_role h5, .non_profit_org h5 {
	text-align: center;
	font-size: 17px;

}

.roles ul, .organizations ul, .non_profit_role ul, .non_profit_org ul {
	padding-left: 10px;
	
}

/* iPad Styling */
@media only screen and (max-width: 1024px) {
	.work {
		height: 100%;
	}

	.work_content {
		position: initial;
		top: initial;
		left: initial;
		transform: initial;
		width: 250px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.work_history {
		flex-direction: column;
	}

	.work_history {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;		

	}

	.work_history h5 {
		font-size: 150%;
	}

	.work_history ul {
		list-style-type: initial;
		font-size: 120%;
		margin-left: 33px;
	}
}