.total-body {
  background-color: #000000;
  font-family: 'Oswald';
  font-weight: 300;
}

.main_container {
  position: relative;
  text-align: center;
  color: white;
}

.welcome_container {
  width: 100%;
  height: 95vh;
  overflow: hidden;
  background-color: #F4F4F4;
  color: #333333;
  background: url('../images/backgrounds/whitekeys.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered_text {
  text-align: center;
  color: #333333;
  

}

.centered_text h1 {
  animation: slideInFromLeft 2s ease-out;
  letter-spacing: 5px;
  font-size: 50px;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateY(-80%);
    letter-spacing: 40px;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    letter-spacing: 5px;
  }
}


/* Make Self Title expand out  */
.centered_text h4 {
  animation: slowAppear forwards;
  animation-delay: 2s;
  animation-duration: 3s;
  letter-spacing: -1px;
  
}

@keyframes slowAppear {
  0% {letter-spacing: -1px;}
  100% {letter-spacing: 4px;}
}

.centered_text a:link, .centered_text a:visited, .centered_text a:active {
  color: #333333;
}

.centered_text a:hover {
  color: #8b0000;
}



.status_notifications {
  display: flex;
  flex-direction: row;
  width: 700px;
}

.statuses {
  flex: 1;
  margin: 10px;
}

/* .statuses p{
  letter-spacing: 1px;
}

.statuses a {
  letter-spacing: 2px;
  font-weight: 500;
} */

.status_title_logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding-left: 20px;
  
}




.social_icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin: 20px 30px;
}

.icon:hover {
  animation: grow 1s ease-out;
  animation-fill-mode: forwards;
}

.social_icons a:link, .social_icons a:visited, .social_icons a:active {
  color: #D1D1D1;
}

.social_icons a:hover {
  color: #8b0000;
  
}

@keyframes grow {
  0% {transform: scale(1);}

  100% {transform: scale(1.2);}
}



.down_arrow {
  color: #8b0000;
  animation: bouncing 2s infinite ease-in-out;
  bottom: 0;
  display: block;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

@keyframes bouncing {
    0% {bottom: 0;}
    50% {bottom: 10px;}
    100% {bottom: 0;}
}



/* Hero Section Responsive Styling */

@media only screen and (max-width: 1250px)  {

  .status_notifications {
    display: flex;
    flex-flow: column;
  }

  .status_title_logo {
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  .social_icons a  {
    margin: 5px;
  }
}