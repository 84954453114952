/* Import Figtree, Satisfy from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Figtree&family=Satisfy&display=swap');

/* Make Super Red */
:root {
  --super-red: #A71930;
}


.timeline {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow-x: hidden;
  background: url('../../images/backgrounds/plane.jpg') no-repeat center center fixed;
  background-size: cover;
  background-color: grey;
  height: 95vh;

}

.timeline h3 {
  color: #333333;
  font-size: 50px;
  letter-spacing: 5px;
  border-radius: 25px;
  background-color: #F4F4F4;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  font-family: 'Bodini';
}

.timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 200px 0;
  margin-left: -100px;
  transition: all 1s;

}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 75px;
  height: 3px;
  background: #F4F4F4;

}



.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: red;
}

/* Bubbles */
.timeline ol li div {
  position: absolute;
  left: calc(100% + 6px);
  width: 120px;
  height: 75px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: #333333;
  background: #F4F4F4;
}

.timeline ol li div::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
  border-radius: 25px 25px 25px 0px;
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: white transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  top: calc(100% + 16px);
  border-radius: 0px 25px 25px 25px;
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.timeline time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Figtree';
  margin-bottom: 8px;
}

.timeline button {
  color: #333333;
  font-size: 20px;
  letter-spacing: 5px;
  border-radius: 25px;
  background-color: #F4F4F4;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  font-family: 'Bangers', Arial;

}

/* Responsive Styling */

@media only screen and (max-width: 1375px) {
  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url('../../images/backgrounds/plane.jpg') no-repeat center;
    background-size: cover;
    background-position-x: -1500px;

    
  }

  #locations {
    display: flex;
  }

  .timeline h3 {
    color: #333333;
    font-size: 200%;
    letter-spacing: 4px;
    border-radius: 25px;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 10px;


  }


  /* Whole Timeline List */
  .timeline ol {
    flex-flow: column nowrap;
    width: 100%;
    margin-left: initial;
    padding-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;

  }

  /* Vertical Line First Part */
  .timeline ol li {
    display: block;
    height: 150px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;


  }

  /* Vertical Line Rest of Line */

  .timeline ol li:not(:first-child) {
    margin-left: auto;
    margin-right: auto;

  }

  /* Purple Dots */

  .timeline ol li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1px);   
    width: 25px;
    height: 25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: var(--super-red);

    
  }

  /* Text Bubbles*/
  .timeline ol li div {
    position: absolute;
    transform: translateX(-50%);
    width: 110px;
    height: 125px;
    padding: 15px;
    
    white-space: normal;
    color: #333333;
    background: #F4F4F4;
    font-size: 16px;
  }

  /* Little Triangles */

  .timeline ol li div::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ol li:nth-child(odd) div {
    top: initial;
    bottom: 60px;
    left: calc(100% - 30px);
    transform: translateX(-100%);
    border-radius: 25px 25px 0px 25px;
  }

  .timeline ol li:nth-child(odd) div::before {
    top: 140px;
    left: 100%;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent #F4F4F4;
  }

  .timeline ol li:nth-child(even) div {
    left: calc(100% + 89px);
    top: initial;
    bottom: calc(100% + -90px);
    border-radius: 25px 25px 25px 0px;
  }

  .timeline ol li:nth-child(even) div::before {
    top: 140px;
    left: -13px;
    border-width: 15px 15px 0 0;
    border-color: transparent #F4F4F4 transparent transparent;
  }

  .timeline time {
    display: block;
    font-size: 115%;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .timeline button {
    font-size: 200%;
    letter-spacing: 5px;
    border-radius: 25px;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

  }
}


/* iPad Styling */

@media only screen and (max-width: 700px) {
  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url('../../images/backgrounds/plane.jpg') no-repeat center;
    background-size: cover;
    background-position-x: -1750px;

    
  }
}



/* iPhone X Styling*/
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {

  /* Timeline Section */
  .timeline {
    height: 100%;
    background: url('../../images/backgrounds/plane.jpg') no-repeat center;
    background-size: cover;
    background-position-x: -2250px;

    
  }

  #locations {
    display: flex;
  }

  .timeline h3 {
    color: #333333;
    font-size: 200%;
    letter-spacing: 4px;
    border-radius: 25px;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 10px;


  }


  /* Whole Timeline List */
  .timeline ol {
    flex-flow: column nowrap;
    width: 100%;
    margin-left: initial;
    padding-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;

  }

  /* Vertical Line First Part */
  .timeline ol li {
    display: block;
    height: 200px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;


  }

  /* Vertical Line Rest of Line */

  .timeline ol li:not(:first-child) {
    margin-left: auto;
    margin-right: auto;

  }

  /* Purple Dots */

  .timeline ol li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1px);   
    width: 25px;
    height: 25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: purple;

    
  }

  /* Text Bubbles*/
  .timeline ol li div {
    position: absolute;
    transform: translateX(-50%);
    width: 110px;
    height: 125px;
    padding: 15px;
    
    white-space: normal;
    color: #333333;
    background: #F4F4F4;
    font-size: 16px;
  }

  /* Little Triangles */

  .timeline ol li div::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ol li:nth-child(odd) div {
    top: initial;
    bottom: 60px;
    left: calc(100% - 30px);
    transform: translateX(-100%);
    border-radius: 25px 25px 0px 25px;
  }

  .timeline ol li:nth-child(odd) div::before {
    top: 140px;
    left: 100%;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent #F4F4F4;
  }

  .timeline ol li:nth-child(even) div {
    left: calc(100% + 89px);
    top: initial;
    bottom: calc(100% + -90px);
    border-radius: 25px 25px 25px 0px;
  }

  .timeline ol li:nth-child(even) div::before {
    top: 140px;
    left: -13px;
    border-width: 15px 15px 0 0;
    border-color: transparent #F4F4F4 transparent transparent;
  }

  .timeline time {
    display: block;
    font-size: 115%;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .timeline button {
    font-size: 200%;
    letter-spacing: 5px;
    border-radius: 25px;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

  }

}

@media only screen and (max-width: 600px) {
  .timeline p, a, li {
    margin: initial;
  }
}