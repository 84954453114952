/* Portfolio */

.portfolio {
	width: 100%;
	position: relative;
	overflow: hidden;
	background: url('../../images/backgrounds/swamp.jpg') no-repeat center center fixed;
	background-size: cover;
	background-color: grey;

}

.portfolio_title {
	text-align: center;
	font-size: 50px;
	font-family: 'Bodoni';
	letter-spacing: 5px;
	margin: 10px;
	background-color: #333;
	color: #F4F4F4;
	border-radius: 25px;
	width: 600px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
	-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.17));
	filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.17););
	text-transform: uppercase;
	padding: 10px 20px; 
}

.portfolio_subheader {
	margin: 10px;
	background-color: #333;
	color: #F4F4F4;
	border-radius: 25px;
	width: 600px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 20px;
}

.portfolio_description {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-size: 15px;
	width: 600px;
	
}

.cards {
	margin: 0px;
}

h1 {
  text-align: center;
}

.cards_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.cards_wrapper {
  position: relative;
  margin: 20px 0 45px;
}

.cards_items {
  margin-bottom: 24px;
  padding: 0;
}

.cards_item {
  display: flex;
  flex: 1;
  margin: .5rem 1rem .5rem 1rem;
  border-radius: 10px;
  background-color: #F4F4F4;
}

.cards_item_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards_items a:hover, .cards_items a:active, .cards_items a:visited, .cards_items a:link {
	text-decoration: none;
	color: #252e48;

}

.cards_item_pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  margin: 10px;
  border-radius: 10px;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards_item_pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards_item_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards_item_img:hover {
  transform: scale(1.1);
}

.cards_item_info {
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;

}

.cards_item_text {
	flex: 1;
	color: #252e48;
	font-size: 18px;
	line-height: 24px;
	margin: 10px 0 0;
	padding: 0px;
}

.cards_item_description {
	flex-flow: 1;
	max-width: 250px;

}

/* Portfolio iPad Styling */



@media only screen and (min-width: 1024px) {
  .cards_items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards_item {
    margin-bottom: 2rem;
  }

  .portfolio_title {
  	font-size: 30px;
  	width: 50%;
  }

  .portfolio_subheader {
  	font-size: 15px;
  	width: 50%;
  }

  .portfolio_description {
  	width: 70%;
  }
}


@media only screen and (max-device-width: 600px) {

	.portfolio_title {
		font-size: 15px;
	}

	.portfolio_subheader {
		font-size: 10px;
	}

	.portfolio_description {
		font-size: 12px;
	}

}